<template>
  <!-- 微信小程序模板 -->
  <div class="wxaTemplate" id="wrap">
    <cardTitleCom
      :cardTitle="`微信小程序模板列表(${
        curTemplate === 'templatedRaf' ? '草稿' : '模板'
      })`"
    >
      <template slot="cardContent">
        <div class="tableContent">
          <!-- 操作按钮 -->
          <operatingButton
            :getList="clickBtn"
            :multiple="multiple"
            :isDeleteDisabled="curTemplate === 'templatedRaf'"
            :isCopyBtn="true"
            :isEnableBtn="true"
            @handleAdd="clickBtn('新增')"
            @handleDelete="clickBtn('删除')"
          >
            <template slot="specialDiyBtn">
              <el-button
                type="primary"
                icon="el-icon-refresh"
                size="mini"
                @click="clickBtn('查询草稿')"
                >查询草稿
              </el-button>
              <el-button
                icon="el-icon-copy-document"
                type="success"
                size="mini"
                @click="clickBtn('生成模板')"
                :disabled="curTemplate === 'templated'"
                >生成模板
              </el-button>
              <el-button
                type="primary"
                icon="el-icon-refresh"
                size="mini"
                @click="clickBtn('查询模板')"
                >查询模板
              </el-button>
              <el-dropdown
                split-button
                size="mini"
                plain
                icon="el-icon-circle-check"
                type="warning"
                class="marL10 marR10"
                @command="settingTemplate"
                :disabled="curTemplate === 'templatedRaf'"
              >
                设置小程序模板
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    :command="dict.value"
                    v-for="dict in dict.type.wx_app_type"
                    :key="dict.value"
                    >{{ dict.label }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </operatingButton>
          <!-- 表格区域 -->
          <div class="table">
            <!-- 草稿列表 -->
            <el-table
              v-if="curTemplate === 'templated'"
              :data="tableData"
              :height="tableHeight"
              :max-height="tableHeight"
              border
              style="width: 100%"
              v-loading="loading"
              ref="table"
              element-loading-text="加载中"
              @row-click="handleRowClick"
            >
              <el-table-column width="55" align="center">
                <template v-slot="scope">
                  <el-radio v-model="curId" :label="scope.row.template_id">
                    <span></span>
                  </el-radio>
                </template>
              </el-table-column>
              <el-table-column
                type="index"
                label="序号"
                width="50"
                align="center"
              >
              </el-table-column>
              <el-table-column
                show-overflow-tooltip
                prop="create_time"
                label="创建时间"
                width="260"
                align="center"
              ></el-table-column>

              <el-table-column
                show-overflow-tooltip
                prop="user_version"
                label="版本号"
                width="150"
                align="center"
              ></el-table-column>
              <el-table-column
                show-overflow-tooltip
                prop="user_desc"
                label="模板描述"
                align="center"
              />
              <el-table-column
                show-overflow-tooltip
                label="当前版本"
                width="150"
                align="center"
                prop="version"
              />
              <el-table-column
                show-overflow-tooltip
                prop="template_id"
                label="模板ID"
                width="130"
                align="center"
              />
            </el-table>
            <!-- 模板列表 -->
            <el-table
              v-else
              :data="tableData"
              :height="tableHeight"
              :max-height="tableHeight"
              border
              style="width: 100%"
              v-loading="loading"
              ref="table"
              @row-click="handleRowClick"
            >
              <el-table-column width="55" align="center">
                <template v-slot="scope">
                  <el-radio v-model="curId" :label="scope.row.draft_id"
                    ><span></span
                  ></el-radio>
                </template>
              </el-table-column>
              <el-table-column
                type="index"
                label="序号"
                width="50"
                align="center"
              >
              </el-table-column>
              <el-table-column
                show-overflow-tooltip
                :prop="item.prop"
                :label="item.title"
                :width="item.width"
                v-for="item in tableFields"
                :key="item.title"
                align="center"
              >
              </el-table-column>
            </el-table>
          </div>
        </div>
      </template>
    </cardTitleCom>
  </div>
</template>

<script>
import OperatingButton from '@/views/components/operatingButton/index.vue' //按钮区域组件
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import {
  TemplatedRaftList,
  TemplateList,
  addTemplate,
  addDefaultTemplate,
  delTemplate,
  getTenantWxAppTemplateList,
  getPrivacySetting
} from '@/api/tenant/config/wxaTemplate' //接口api
import { timestampToTime } from '@/utils/index.js' //时间戳转日期
export default {
  name: 'wxaTemplate',
  components: { OperatingButton, cardTitleCom },
  dicts: ['wx_app_type'],
  data () {
    return {
      templateList: [],
      loading: false, //遮罩层
      typeTitle: '查询草稿',
      tableData: [],
      curId: undefined, //表格选中的id
      curTemplate: 'templatedRaf', //当前模板(templatedRaf草稿模板,templated模板)
      //表格字段(默认商品表格字段)
      tableFields: [
        { prop: 'create_time', title: '创建时间', width: '260' },
        { prop: 'user_version', title: '版本号', width: '150' },
        { prop: 'user_desc', title: '模板描述' },
        { prop: 'version', title: '当前版本', width: '150' },
        { prop: 'draft_id', title: '模板ID', width: '130' }
      ],
      queryParams: {}, //查询
      total: 1,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: false,
      vivwH: 0 //页面高度
    }
  },
  computed: {
    tableHeight () {
      return this.vivwH - 120
    }
  },
  created () {
    this.getTemplatedRafList()
  },
  mounted () {
    this.$nextTick(() => {
      this.vivwH = document.getElementById('wrap').clientHeight
    })
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize () {
      this.vivwH = document.getElementById('wrap').clientHeight
    },
    //点击按钮事件 type参数区分类型(查询草稿/查询模板/生成模板/设置小程序模板/删除/刷新)
    async clickBtn (type) {
      if (!type) {
        type = this.typeTitle
      } else {
        this.typeTitle = type
      }
      if (type === '查询草稿') {
        await this.getTemplatedRafList()
      } else if (type === '查询模板') {
        await this.getTemplatedList()
      } else if (type === '生成模板') {
        if (this.curId === undefined) return this.$message.error('请选择草稿')
        this.loading = true
        const res = await addTemplate({ draft_id: this.curId })
         this.$message.success('生成模板成功')
        this.loading = false
      } else if (type === '删除') {
        console.log('curId', this.curId)
        if (this.curId === undefined) return this.$message.error('请选择模版')
        if (this.curTemplate === 'templatedRaf')
          return this.$message.error('草稿不允许删除')
        const res = await delTemplate({
          template_id: this.curId
        })
         this.$message.success('删除模板成功')
          await this.getTemplatedList()
      } else if (type === '刷新') {
      }
    },
    handleRowClick (row) {
      if (this.curTemplate === 'templated') {
        this.curId = row.template_id
      } else {
        this.curId = row.draft_id
      }
    },
    // 用户隐私
    // async setPrivacy () {
    //   let wxAppId
    //   this.tableData.forEach(item => {
    //     if (this.curId === item.template_id) {
    //       wxAppId = item.source_miniprogram_appid
    //     }
    //   })
    //   this.loading = true

    //   try {
    //     await getPrivacySetting({
    //       appId: wxAppId,
    //       contactWeixin: 'sunny',
    //       noticeMethod: '隼云科技收集信息',
    //       setting_list: [
    //         {
    //           privacy_key: 'UserInfo',
    //           privacy_text: '用来登记会员信息'
    //         },
    //         {
    //           privacy_key: 'Location',
    //           privacy_text: '用来展示你的位置信息'
    //         },
    //         {
    //           privacy_key: 'Address',
    //           privacy_text: '用来展示你的地址'
    //         },
    //         {
    //           privacy_key: 'PhoneNumber',
    //           privacy_text: '用来查看信息'
    //         },
    //         {
    //           privacy_key: 'Album',
    //           privacy_text: '选中的照片或视频信息'
    //         },
    //         {
    //           privacy_key: 'AlbumWriteOnly',
    //           privacy_text: '相册（仅写入）权限'
    //         },
    //         {
    //           privacy_key: 'ChooseLocation',
    //           privacy_text: '选择的位置信息'
    //         }
    //       ]
    //     })
    //     this.getTemplatedList()
    //     this.$message.success('配置用户隐私成功')
    //     this.loading = false
    //   } catch (err) {
    //     this.$message.success('配置用户隐私失败')
    //     this.loading = false
    //   }
    // },

    // 设置模板
    async settingTemplate (type) {
      if (this.curId === undefined) return this.$message.error('请选择模板')
      this.loading = true
      try {
        let wxAppId
        this.tableData.forEach(item => {
          if (this.curId === item.template_id) {
            wxAppId = item.source_miniprogram_appid
          }
        })
        await addDefaultTemplate({
          wxAppId: wxAppId,
          wxAppType: type,
          wxTemplateId: this.curId
        })
        this.getTemplatedList()
        this.$message.success('设置模板成功')
        this.loading = false
      } catch {
        this.loading = false
      }
    },
    //获取小程序草稿列表
    async getTemplatedRafList () {
      this.loading = true
      let res = await TemplatedRaftList()
      if (res.data?.errcode < 40000 && res.data?.errcode >= 0) {
        this.curTemplate = 'templatedRaf'
        this.curId = undefined
        this.tableData = res.data.draft_list.reverse()
        this.tableData.forEach(item => {
          item.create_time = timestampToTime(item.create_time)
        })
      }
      this.loading = false
    },
    //获取小程序模板列表
    async getTemplatedList () {
      this.loading = true
      let resK = await TemplateList()
      let res = resK.data
      let resList = await getTenantWxAppTemplateList()
      if (res.errcode < 40000 && res.errcode >= 0) {
        this.curTemplate = 'templated'
        this.curId = undefined

        res.template_list.forEach((item, index) => {
          item.create_time = timestampToTime(item.create_time)

          const find = resList.data.find(
            temp => item.template_id === temp.wxTemplateId
          )

          item.version = find
            ? this.selectDictLabel(this.dict.type.wx_app_type, find.wxAppType)
            : ''
        })

        this.tableData = res.template_list.reverse()
      }
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.wxaTemplate {
  height: calc(100vh - 84px);
  padding: 6px;
  background-color: #eaeaea;

  .tableContent {
    padding: 10px;
  }
}
</style>
